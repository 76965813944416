<template>
    <r-e-dialog title="账单" :visible.sync="dialogVisible" top="5vh" width="1500px">
        <r-e-table ref="communityTableRef" :dataRequest="getPaidTableData" :columns="billColumns" :height="500"
                   :showPagination="false" show-summary :summary-method="getSummaries"/>
    </r-e-dialog>
</template>

<script>
import {billColumns} from "@/views/rental-management/synthetical-management/data";
import {getPaybillsByContract} from "@/api/bill";
import {numberFormat} from "@custom/index";

export default {
    name: "dialog-bill-list",
    data() {
        return {
            dialogVisible: false,
            billColumns: billColumns,
            billData: null,
            detailsDialogVisible: false,
        }
    },
    components: {},
    methods: {
        //打开账单列表弹窗
        openDialog(data) {
            let {uuid} = data;
            this.uuid = uuid;
            this.dialogVisible = true;
        },

        //账单列表查询请求
        getPaidTableData(params) {
            params.statusCode = "";
            params.type = "";
            return getPaybillsByContract(this.uuid, params);
        },

        //账单列表查询方法
        handleSearch() {
            this.$refs["communityTableRef"].getTableData(true);
        },

        //账单列表合计方法
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                if (index === 2) {
                    return;
                }
                const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) return prev + curr;
                        else return prev;
                    }, 0);
                    sums[index] = numberFormat(sums[index] / 100);
                } else sums[index] = '';
            });
            return sums;
        },
    },
}
</script>

<style scoped>

</style>